import { ReactNode } from "react";
import { Navigate } from "react-router";
import { Container, Alert, AlertTitle } from "@mui/material";
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user } = useAuth();

  if (!accessibleRoles.includes(user?.role)) {
    if (user?.role != "admin") {
      return (
        // <Container>
        //   <Alert severity="error">
        //     <AlertTitle>Permission Denied</AlertTitle>
        //     You do not have permission to access this page
        //   </Alert>
        // </Container>
        <Navigate to="/dashboard/app" replace />
      );
    } else {
      return (
        // <Container>
        //   <Alert severity="error">
        //     <AlertTitle>Permission Denied</AlertTitle>
        //     You do not have permission to access this page
        //   </Alert>
        // </Container>
        <Navigate to="/dashboard/analytics" replace />
      );
    }
  }

  return <>{children}</>;
}
