import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const Types = {
  Initial: "INITIALIZE",
  Login: "LOGIN",
  Logout: "LOGOUT",
  Register: "REGISTER",
  USER_UPDATE: "USER_UPDATE",
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null,
  microscopy_profile: [],
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        company: action.payload?.company,
        microscopy_profile: action.payload?.microscopy_profile,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        company: action.payload?.company,
        microscopy_profile: action.payload?.microscopy_profile,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        company: null,
        microscopy_profile: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        company: action.payload?.company,
        microscopy_profile: action.payload?.microscopy_profile,
      };

    case "USER_UPDATE":
      return {
        ...state,
        user: action.payload.user,
        company: action.payload?.company,
        microscopy_profile: action.payload?.microscopy_profile,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get("/api/v1/user/my-account");
          const { user, company, microscopy_profile } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
              company,
              microscopy_profile,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              company: null,
              microscopy_profile: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            company: null,
            microscopy_profile: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/api/v1/auth/signin", {
      email,
      password,
    });
    const { access_token, user, company, microscopy_profile } = response.data;

    setSession(access_token);
    dispatch({
      type: Types.Login,
      payload: {
        user,
        company,
        microscopy_profile,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/v1/auth/signup", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email) => console.log(email);

  const updateProfile = (user) => {
    dispatch({
      type: Types.USER_UPDATE,
      payload: {
        user: user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
