import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import companyReducer from "./slices/company";
import segmentationReducer from "./slices/segmentation";
import analyticsReducer from "./slices/analytics";
import microscopy_profileReducer from "./slices/microscopy_profile";
import segmentation_taskReducer from "./slices/segmentation_task";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const companyPersistConfig = {
  key: "company",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  user: userReducer,
  segmentation: segmentationReducer,
  company: persistReducer(companyPersistConfig, companyReducer),
  analytics: analyticsReducer,
  microscopy_profile: microscopy_profileReducer,
  segmentation_task: segmentation_taskReducer,
});

export { rootPersistConfig, rootReducer };
