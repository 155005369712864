import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import React, { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import moment from "moment";
// material
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  Grid,
  Stack,
  Box,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import {
  downloadAllFiles,
  downloadFile,
  getSegmentationById,
  setSegmentation,
} from "../../redux/slices/segmentation";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import fileDownload from "js-file-download";
import DualPhasePhaseAnalysis from "components/_dashboard/segmentation-detail/DualPhasePhaseAnalysis";
import DualPhaseGrainAnalysis from "components/_dashboard/segmentation-detail/DualPhaseGrainAnalysis";
import GrainAnalysis from "components/_dashboard/segmentation-detail/GrainAnalysis";
import SelectedConfigurations from "components/_dashboard/segmentation-detail/SelectedConfigurations";

// ----------------------------------------------------------------------

export default function SegmentationDetail() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const { segmentationId } = useParams();
  const { segmentation } = useSelector((state) => state.segmentation);

  useEffect(() => {
    if (location?.state?._id) {
      console.log(location.state);
      dispatch(setSegmentation(location.state));
    } else {
      if (segmentationId) {
        dispatch(getSegmentationById(segmentationId));
      }
    }
  }, [dispatch]);

  if (!segmentation) return <></>;

  return (
    <Page title="Segmentation: Detail | MECS">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading="Segmentation Detail"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Segmentation",
              href: PATH_DASHBOARD.segmentation.list,
            },
            { name: segmentation?.original_filename },
          ]}
          // action={
          //   <Button
          //     variant="contained"
          //     component={RouterLink}
          //     to={PATH_DASHBOARD.general.app}
          //     startIcon={<Icon icon={plusFill} />}
          //   >
          //     New Segmentation
          //   </Button>
          // }
        />

        <Card sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"right"} gap={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (segmentation?.task == "DualPhaseAnalysis") {
                      dispatch(
                        downloadFile(
                          segmentation?.dual_phase_grain_analysis_results[
                            "pdf_report"
                          ]
                        )
                      );
                    }
                    if (segmentation?.task == "GrainAnalysis") {
                      dispatch(
                        downloadFile(
                          segmentation?.grain_analysis_results["pdf_report"]
                        )
                      );
                    }
                  }}
                >
                  Download PDF Report
                </Button>
                <Button
                  variant="contained"
                  onClick={() => dispatch(downloadAllFiles(segmentation))}
                >
                  Download All Files
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack display="flex" direction="column">
                <Stack display="flex" direction="row">
                  <Box sx={{ width: 280 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Task
                    </Typography>
                  </Box>
                  <Typography variant="body" sx={{ mb: 2 }}>
                    {segmentation?.task}
                  </Typography>
                </Stack>

                <Stack display="flex" direction="row">
                  <Box sx={{ width: 280 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Models
                    </Typography>
                  </Box>
                  <Typography variant="body" sx={{ mb: 2 }}>
                    {segmentation?.ml_models?.map((item, index) => (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </Stack>

                <Stack display="flex" direction="row">
                  <Box sx={{ width: 280 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Created At
                    </Typography>
                  </Box>
                  <Typography variant="body" sx={{ mb: 2 }}>
                    {moment(segmentation?.created_at).format(
                      "DD-MM-YYYY h:mm A"
                    )}
                  </Typography>
                </Stack>

                <SelectedConfigurations
                  configurations={segmentation?.configurations}
                />

                <Stack display="flex" direction="row">
                  <Box sx={{ width: 280, alignSelf: "center" }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Input Image Name
                    </Typography>
                  </Box>
                  <Typography variant="body" sx={{ mb: 2 }}>
                    {segmentation?.original_filename}
                  </Typography>
                </Stack>

                {segmentation?.input_file && (
                  <Stack display="flex" direction="row" sx={{ mb: 2 }}>
                    <Box sx={{ width: 280, alignSelf: "center" }}>
                      <Typography variant="h6">Input Image</Typography>
                    </Box>

                    <Box sx={{ alignSelf: "center" }}>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          dispatch(downloadFile(segmentation?.input_file))
                        }
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                )}

                {segmentation?.task == "DualPhaseAnalysis" && (
                  <>
                    <DualPhasePhaseAnalysis
                      dual_phase_phase_analysis_results={
                        segmentation?.dual_phase_phase_analysis_results
                      }
                    />

                    <DualPhaseGrainAnalysis
                      dual_phase_grain_analysis_results={
                        segmentation?.dual_phase_grain_analysis_results
                      }
                    />
                  </>
                )}

                {segmentation?.task == "GrainAnalysis" && (
                  <GrainAnalysis
                    grain_analysis_results={
                      segmentation?.grain_analysis_results
                    }
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}
