import { createSlice } from "@reduxjs/toolkit";
import { sum, map, filter, uniqBy } from "lodash";
import { store, dispatch } from "../store";
import fileDownload from "js-file-download";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  microscopy_profiles: [],
};

const slice = createSlice({
  name: "microscopy_profile",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getMicroscopyProfilesSuccess(state, action) {
      state.isLoading = false;
      state.microscopy_profiles = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function createMicroscopyProfile(body) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/api/v1/microscopy_profile/create",
        body
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMicroscopyProfiles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/v1/microscopy_profile/all");
      dispatch(
        slice.actions.getMicroscopyProfilesSuccess(
          response.data.microscopy_profiles
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompaniesMicroscopyProfiles(company) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/microscopy_profile/companyid/${company}`
      );
      dispatch(
        slice.actions.getMicroscopyProfilesSuccess(
          response?.data?.microscopy_profiles
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
