// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "app",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        accessibleRoles: ["user"],
      },
      {
        title: "analytics",
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.analytics,
        accessibleRoles: ["admin"],
      },
      {
        title: "Segmentations",
        path: PATH_DASHBOARD.segmentation.list,
        icon: ICONS.dashboard,
        accessibleRoles: ["user"],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      // MANAGEMENT : USER
      {
        title: "user",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: "profile", path: PATH_DASHBOARD.user.profile },
          // { title: "cards", path: PATH_DASHBOARD.user.cards },
          { title: "list", path: PATH_DASHBOARD.user.list },
          { title: "create", path: PATH_DASHBOARD.user.newUser },
          // { title: "edit", path: PATH_DASHBOARD.user.editById },
          // { title: "account", path: PATH_DASHBOARD.user.account },
        ],
        accessibleRoles: ["admin"],
      },
      // MANAGEMENT : COMPANY
      {
        title: "company",
        path: PATH_DASHBOARD.company.root,
        icon: ICONS.banking,
        children: [
          // { title: "profile", path: PATH_DASHBOARD.user.profile },
          // { title: "cards", path: PATH_DASHBOARD.user.cards },
          { title: "list", path: PATH_DASHBOARD.company.list },
          { title: "create", path: PATH_DASHBOARD.company.newCompany },
          // { title: "edit", path: PATH_DASHBOARD.user.editById },
          // { title: "account", path: PATH_DASHBOARD.user.account },
        ],
        accessibleRoles: ["admin"],
      },
      {
        title: "segmentation tasks",
        path: PATH_DASHBOARD.segmentation_task.root,
        icon: ICONS.booking,
        children: [
          {
            title: "list",
            path: PATH_DASHBOARD.segmentation_task.list,
          },
          { title: "create", path: PATH_DASHBOARD.segmentation_task.create },
        ],
        accessibleRoles: ["admin"],
      },
      {
        title: "Microscopy Profiles",
        path: PATH_DASHBOARD.microscopy_profile.root,
        icon: ICONS.kanban,
        children: [
          {
            title: "list",
            path: PATH_DASHBOARD.microscopy_profile.list,
          },
          { title: "create", path: PATH_DASHBOARD.microscopy_profile.create },
        ],
        accessibleRoles: ["admin"],
      },
    ],
  },
];

export default sidebarConfig;
