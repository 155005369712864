// material
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  Grid,
  Stack,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { downloadFile } from "../../../redux/slices/segmentation";

// components
import fileDownload from "js-file-download";

// ----------------------------------------------------------------------

const GRAIN_ANALYSIS = [
  {
    key: "overlay_grain_analysis",
    label: "Grain Boundary Segmentation",
  },
  {
    key: "overlay_grain_analysis",
    label: "GB Segmentation post-processed",
  },
  {
    key: "colored_overlay_grain_analysis",
    label: "Detected & Analyzed Grains",
  },
  {
    key: "watershed_grain_analysis",
    label: "Grain Analysis Overlay",
  },
  {
    key: "histogram_plot_grain_analysis",
    label: "Grain Size Histogram",
  },
  {
    key: "csv_grain_size_grain_analysis",
    label: "Global Grain Measurements",
  },
  {
    key: "csv_grain_size_mean_grain_analysis",
    label: "Detailed Grain Measurements",
  },
];

export default function DualPhaseGrainAnalysis({
  dual_phase_grain_analysis_results,
}) {
  const dispatch = useDispatch();

  return (
    <Grid item xs={12}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Grain Analysis Results
      </Typography>

      {GRAIN_ANALYSIS?.map((item, index) => (
        <>
          {dual_phase_grain_analysis_results?.[item?.key] && (
            <>
              <Stack
                display="flex"
                direction="row"
                key={index}
                sx={{
                  p: 1,
                  mb: 1,
                  "&:hover": {
                    bgcolor: "primary.lighter",
                  },
                }}
              >
                <Box sx={{ alignSelf: "center" }}>
                  <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
                    {item?.label}
                  </Typography>
                </Box>

                <Box sx={{ ml: "auto", alignSelf: "center" }}>
                  <IconButton
                    sx={{ ml: 3 }}
                    color="primary"
                    onClick={() =>
                      dispatch(
                        downloadFile(
                          dual_phase_grain_analysis_results[item?.key]
                        )
                      )
                    }
                  >
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </Stack>
            </>
          )}
        </>
      ))}
    </Grid>
  );
}
