import { createSlice } from "@reduxjs/toolkit";
import { sum, map, filter, uniqBy } from "lodash";
import { store } from "../store";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  companyList: [],
  company: null,
  sortBy: null,
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COMPANIES
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companyList = action.payload;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getCompanySuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getCompanyList() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/v1/company/all");
      dispatch(slice.actions.getCompaniesSuccess(response.data.companies));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(companyId) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/company/companyid/${companyId}`
      );
      dispatch(slice.actions.getCompanySuccess(response.data.company));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCompany(body) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/v1/company/create", body);
      return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCompany(companyId, body) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/v1/company/companyid/${companyId}`,
        body
      );
      return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
